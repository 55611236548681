import tinkersynthDemoSrc from '@assets/videos/tinkersynth-demo.mp4';
import tinkersynthColorPickerSrc from '@assets/videos/ts-color-picker-large.mp4';
import tinkersynthStoreSrc from '@assets/images/tinkersynth-store.png';
import tinkersynthColorsSrc from '@assets/images/tinkersynth-colors@2x.png';
import tinkersynthLineThicknessSrc from '@assets/images/tinkersynth-line-thickness.png';
import TextLink from '@components/TextLink';
import List from '@components/List';
import ListItem from '@components/ListItem';
import VideoGif from '@components/VideoGif';
import Image from '@components/Image';
import React from 'react';
export default {
  tinkersynthDemoSrc,
  tinkersynthColorPickerSrc,
  tinkersynthStoreSrc,
  tinkersynthColorsSrc,
  tinkersynthLineThicknessSrc,
  TextLink,
  List,
  ListItem,
  VideoGif,
  Image,
  React
};
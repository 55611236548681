import Sidenote from "../../../../src/components/Sidenote";
import CenteredImage from "../../../../src/components/CenteredImage";
import DeEmphasizedTextLink from "../../../../src/components/DeEmphasizedTextLink";
import List from "../../../../src/components/List";
import ListItem from "../../../../src/components/ListItem";
import SideBySide from "../../../../src/components/SideBySide";
import TextLink from "../../../../src/components/TextLink";
import Center from "../../../../src/components/Center";
import Tweet from "../../../../src/components/Tweet";
import jobSpiralSrc from "../../../../src/assets/images/remote-work/job-spiral-gradient.png";
import NewsletterSignup from '@components/NewsletterSignup';
import React from 'react';
export default {
  Sidenote,
  CenteredImage,
  DeEmphasizedTextLink,
  List,
  ListItem,
  SideBySide,
  TextLink,
  Center,
  Tweet,
  jobSpiralSrc,
  NewsletterSignup,
  React
};
import Asterisk from '@components/Asterisk';
import Tweet from '@components/Tweet';
import SideBySide from "../../../../src/components/SideBySide";
import generative1 from '@assets/images/designer-collaboration/creative-code-matt-desl-1.jpeg';
import generative2 from '@assets/images/designer-collaboration/creative-code-manoloidee-1.jpeg';
import generative3 from '@assets/images/designer-collaboration/creative-code-3.jpeg';
import generative4 from '@assets/images/designer-collaboration/creative-code-4.png';
import NewsletterSignup from "../../../../src/components/NewsletterSignup";
import React from 'react';
export default {
  Asterisk,
  Tweet,
  SideBySide,
  generative1,
  generative2,
  generative3,
  generative4,
  NewsletterSignup,
  React
};
import TextLink from '@components/TextLink';
import List from '@components/List';
import ListItem from '@components/ListItem';
import VideoGif from '@components/VideoGif';
import Image from '@components/Image';
import NewsletterSignup from '@components/NewsletterSignup';
import Center from '@components/Center';
import RenderWhenOnscreen from '@components/RenderWhenOnscreen';
import LiveEditableCode from '@components/LiveEditableCode';
import Code from '@components/Code';
import Asterisk from '@components/Asterisk';
import registerPropertySrc from '@assets/images/rainbow-button/register-property-compatibility.png';
import profilingSrc from '@assets/images/rainbow-button/profiling.png';
import customPropsCode from "../../../../src/pages/posts/rainbow-button/code/custom-props.example";
import customPropsCssCode from "../../../../src/pages/posts/rainbow-button/code/custom-props-css.example";
import cssVarsConceptCode from "../../../../src/pages/posts/rainbow-button/code/css-vars-concept.example";
import customPropsTransitionCode from "../../../../src/pages/posts/rainbow-button/code/custom-props-transition.example";
import fakeNewsGradientCode from "../../../../src/pages/posts/rainbow-button/code/fake-news-gradient.example";
import registerPropertyCode from "../../../../src/pages/posts/rainbow-button/code/register-property.example";
import vanillaDemoCode from "../../../../src/pages/posts/rainbow-button/code/vanilla-demo.example";
import hookConsumerCode from "../../../../src/pages/posts/rainbow-button/code/hook-consumer.example";
import initialUseRainbowCode from "../../../../src/pages/posts/rainbow-button/code/initial-use-rainbow.example";
import uniqueIdCode from "../../../../src/pages/posts/rainbow-button/code/unique-id.example";
import prmCode from "../../../../src/pages/posts/rainbow-button/code/prm.example";
import OldMethod from "../../../../src/pages/posts/rainbow-button/components/OldMethod";
import GradientIdeaImage from "../../../../src/pages/posts/rainbow-button/components/GradientIdeaImage";
import CasinoLights from "../../../../src/pages/posts/rainbow-button/components/CasinoLights";
import DemoButton from "../../../../src/pages/posts/rainbow-button/components/DemoButton";
import React from 'react';
export default {
  TextLink,
  List,
  ListItem,
  VideoGif,
  Image,
  NewsletterSignup,
  Center,
  RenderWhenOnscreen,
  LiveEditableCode,
  Code,
  Asterisk,
  registerPropertySrc,
  profilingSrc,
  customPropsCode,
  customPropsCssCode,
  cssVarsConceptCode,
  customPropsTransitionCode,
  fakeNewsGradientCode,
  registerPropertyCode,
  vanillaDemoCode,
  hookConsumerCode,
  initialUseRainbowCode,
  uniqueIdCode,
  prmCode,
  OldMethod,
  GradientIdeaImage,
  CasinoLights,
  DemoButton,
  React
};
module.exports = [{
      plugin: require('/home/project/blog/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"posts":"/home/project/blog/src/components/BlogPost/BlogPost.js"}},
    },{
      plugin: require('/home/project/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"","cookieExpires":63072000},
    },{
      plugin: require('/home/project/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import Sidenote from "../../../../src/components/Sidenote";
import List from "../../../../src/components/List";
import ListItem from "../../../../src/components/ListItem";
import DeEmphasizedTextLink from "../../../../src/components/DeEmphasizedTextLink";
import deskSrc from "../../../../src/assets/images/remote-work/desk.jpg";
import PhotoSet from "../../../../src/components/PhotoSet";
import pianoSrc from "../../../../src/assets/images/remote-work/piano-night.jpg";
import viewSrc from "../../../../src/assets/images/remote-work/view.jpg";
import HighlightedSection from "../../../../src/components/HighlightedSection";
import NewsletterSignup from "../../../../src/components/NewsletterSignup";
import React from 'react';
export default {
  Sidenote,
  List,
  ListItem,
  DeEmphasizedTextLink,
  deskSrc,
  PhotoSet,
  pianoSrc,
  viewSrc,
  HighlightedSection,
  NewsletterSignup,
  React
};
import { Spring } from 'react-spring/renderprops';
import { State } from 'react-powerplug';
import styled, { keyframes } from 'styled-components';
import cssEngineSrc from '@assets/videos/css-engine.mp4';
import partTwoPreview from '@assets/videos/folding-pt2-pre.mp4';
import foldDemoImageSmallSrc from '@assets/images/francois-hoang-china-small.jpg';
import catsSrc from '@assets/images/cats.jpg';
import foldIssueSrc from '@assets/images/fold-issue.gif';
import Demo from '@components/Demo';
import FoldingDemo from '@components/FoldingDemo';
import MultipleChoiceControl, { Choice } from '@components/MultipleChoiceControl';
import SliderControl from '@components/SliderControl';
import TextLink from '@components/TextLink';
import SingleAxisDemo from '@components/SingleAxisDemo';
import VideoGif from '@components/VideoGif';
import InlineCode from '@components/InlineCode';
import Image from '@components/Image';
import AutoRotate from '@components/AutoRotate';
import LiveEditableCode from '@components/LiveEditableCode';
import RenderWhenOnscreen from '@components/RenderWhenOnscreen';
import MobileWarning from '@components/MobileWarning';
import NewsletterSignup from '@components/NewsletterSignup';
import mvpExampleCode from "../../../../src/pages/posts/folding-the-dom/code/mvp.example";
import mvpA11yExampleCode from "../../../../src/pages/posts/folding-the-dom/code/mvp-a11y.example";
import mvpWithBacksideExampleCode from "../../../../src/pages/posts/folding-the-dom/code/mvp-with-backside.example";
import backfaceInitialCode from "../../../../src/pages/posts/folding-the-dom/code/backface-initial.example";
import backfaceHiddenCode from "../../../../src/pages/posts/folding-the-dom/code/backface-hidden.example";
import backfaceFixedCode from "../../../../src/pages/posts/folding-the-dom/code/backface-fixed.example";
import React from 'react';
export default {
  Spring,
  State,
  styled,
  keyframes,
  cssEngineSrc,
  partTwoPreview,
  foldDemoImageSmallSrc,
  catsSrc,
  foldIssueSrc,
  Demo,
  FoldingDemo,
  MultipleChoiceControl,
  Choice,
  SliderControl,
  TextLink,
  SingleAxisDemo,
  VideoGif,
  InlineCode,
  Image,
  AutoRotate,
  LiveEditableCode,
  RenderWhenOnscreen,
  MobileWarning,
  NewsletterSignup,
  mvpExampleCode,
  mvpA11yExampleCode,
  mvpWithBacksideExampleCode,
  backfaceInitialCode,
  backfaceHiddenCode,
  backfaceFixedCode,
  React
};